// 從新的 Cloudflare API 客戶端導入並重新導出
import { cloudflareApi, setSSRContext as setCloudflareSSRContext } from './cloudflare-api.js';
import axiosInstance from './cloudflare-api.js';

// 為了向後兼容，重新導出 videoApi
export const videoApi = cloudflareApi;

// 為了向後兼容，重新導出 setSSRContext
export const setSSRContext = (url, env) => {
  // 調用新的 setSSRContext 函數
  setCloudflareSSRContext(url, env);
};

// 為了向後兼容，導出默認的 axios 實例
export default axiosInstance;
