import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { localizedLink } from '../../utils/linkHelper';
import { videoApi } from '../../api/api';
import VideoCard from '../../components/VideoCard';
import Loading from '../../components/Loading';
import { getOrigin, scrollToTop, reloadPage } from '../../utils/serverUtils';
import seoConfig from '../../seo-config.json';

const Category = ({ type }) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language || 'tw';
  const { tag, manufacturer, actor } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  
  const [videos, setVideos] = useState([]);
  const [pagination, setPagination] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [error, setError] = useState(null);
  const [sort, setSort] = useState('upload_date');
  const [allTags, setAllTags] = useState([]);
  const [allManufacturers, setAllManufacturers] = useState([]);
  const [allActors, setAllActors] = useState([]);
  const [activeTab, setActiveTab] = useState('manufacturers'); // 'manufacturers', 'actors', 'tags', 'code'
  const [searchTerm, setSearchTerm] = useState('');
  const [codeSearchTerm, setCodeSearchTerm] = useState('');
  const [codeSearchResults, setCodeSearchResults] = useState([]);
  const [isCodeSearching, setIsCodeSearching] = useState(false);
  const [showAllCodeResults, setShowAllCodeResults] = useState(false);
  const [hasCodeSearched, setHasCodeSearched] = useState(false);
  const [topVideos, setTopVideos] = useState([]);
  const [loadingTopVideos, setLoadingTopVideos] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingCategories, setLoadingCategories] = useState(true); // 新增：用於跟踪分類數據的加載狀態
  
  // 從 URL 獲取初始頁碼
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const page = parseInt(searchParams.get('page') || '1', 10);
    setCurrentPage(page);
  }, [location.search]);
  
  // 從 URL 獲取搜索查詢
  const query = useParams().query;
  
  // 確定當前分類類型和值
  let categoryType = type || (tag ? 'tag' : manufacturer ? 'manufacturer' : actor ? 'actor' : query ? 'search' : null);
  const categoryValue = tag || manufacturer || actor || query || null;
  
  // 處理特殊路徑 /category/tag 和 /category/manufacturer
  if (!categoryType) {
    const pathParts = location.pathname.split('/');
    if (pathParts.length >= 3) {
      if (pathParts[2] === 'tag') {
        categoryType = 'tag_list';
      } else if (pathParts[2] === 'manufacturer') {
        categoryType = 'manufacturer_list';
      }
    }
  }
  
  // 頁面標題
  let pageTitle = t('category.allCategories');
  if (categoryType === 'tag' && tag) {
    pageTitle = `${t('video.tags')}: ${tag}`;
  } else if (categoryType === 'manufacturer' && manufacturer) {
    pageTitle = `${t('video.manufacturer')}: ${manufacturer}`;
  } else if (categoryType === 'actor' && actor) {
    pageTitle = `${t('video.actors')}: ${actor}`;
  } else if (categoryType === 'search' && query) {
    pageTitle = `${t('common.search')}: ${decodeURIComponent(query)}`;
  }
  
  // 設置初始活動標籤
  useEffect(() => {
    if (categoryType === 'tag' || categoryType === 'tag_list') {
      setActiveTab('tags');
    } else if (categoryType === 'manufacturer' || categoryType === 'manufacturer_list') {
      setActiveTab('manufacturers');
    } else if (categoryType === 'actor') {
      setActiveTab('actors');
    }
  }, [categoryType]);
  
  // 獲取熱門影片（近兩個月觀看數最高的前10個）
  useEffect(() => {
    if (activeTab === 'code' && !hasCodeSearched && !topVideos.length) {
      const fetchTopVideos = async () => {
        try {
          setLoadingTopVideos(true);
          const data = await videoApi.getPopularVideos(10);
          setTopVideos(data || []);
          setLoadingTopVideos(false);
        } catch (err) {
          console.error('Error fetching top videos:', err);
          setLoadingTopVideos(false);
        }
      };
      
      fetchTopVideos();
    }
  }, [activeTab, hasCodeSearched, topVideos.length]);
  
  // 處理番號搜尋
  const handleCodeSearch = async () => {
    if (!codeSearchTerm.trim()) return;
    
    try {
      setIsCodeSearching(true);
      setHasCodeSearched(true);
      
      // 使用專門的番號搜索API
      const data = await videoApi.searchByBarcode(codeSearchTerm.trim());
      
      // 檢查API返回的數據是否有效
      if (!data || data.error) {
        console.error('API返回錯誤:', data?.error || '未知錯誤');
        // 設置空結果
        setCodeSearchResults([]);
        setShowAllCodeResults(false);
        setIsCodeSearching(false);
        return;
      }
      
      console.log(`番號搜尋: "${codeSearchTerm.trim()}", 找到 ${data.videos.length} 個結果`);
      
      setCodeSearchResults(data.videos || []);
      setShowAllCodeResults(false);
      setIsCodeSearching(false);
    } catch (err) {
      console.error('Error searching code:', err);
      // 設置空結果
      setCodeSearchResults([]);
      setIsCodeSearching(false);
    }
  };
  
  // 處理番號搜尋輸入變更
  const handleCodeSearchChange = (e) => {
    setCodeSearchTerm(e.target.value);
  };
  
  // 處理番號搜尋按鈕點擊
  const handleCodeSearchSubmit = (e) => {
    e.preventDefault();
    handleCodeSearch();
  };
  
  // 加載更多番號搜尋結果
  const loadMoreCodeResults = () => {
    setShowAllCodeResults(true);
  };
  
  // 獲取所有標籤、片商和演員
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        setLoadingCategories(true); // 開始加載分類數據
        const [tagsData, manufacturersData, actorsData] = await Promise.all([
          videoApi.getTags(),
          videoApi.getManufacturers(),
          videoApi.getActors()
        ]);
        
        setAllTags(tagsData);
        setAllManufacturers(manufacturersData);
        setAllActors(actorsData);
        setLoadingCategories(false); // 完成加載分類數據
      } catch (err) {
        console.error('Error fetching categories:', err);
        setLoadingCategories(false); // 加載失敗時也要設置為 false
      }
    };
    
    fetchCategories();
  }, []);
  
  // 當分類類型或值變化時滾動到頁面頂部
  useEffect(() => {
    // 滾動到頁面頂部，增強用戶體驗
    scrollToTop();
  }, [categoryType, categoryValue]);
  
  // 獲取影片列表
  useEffect(() => {
    const fetchVideos = async () => {
      if (!categoryType) return; // 如果沒有分類類型，不獲取影片
      
      try {
        setLoading(true);
        
        let data;
        // 搜索結果只顯示5個，其他顯示20個
        const limit = categoryType === 'search' ? 5 : 20;
        const params = { page: currentPage, limit, sort };
        
        if (categoryType === 'tag' && tag) {
          data = await videoApi.getVideosByTag(tag, params);
        } else if (categoryType === 'manufacturer' && manufacturer) {
          data = await videoApi.getVideosByManufacturer(manufacturer, params);
        } else if (categoryType === 'actor' && actor) {
          data = await videoApi.getVideosByActor(actor, params);
        } else if (categoryType === 'search' && query) {
          data = await videoApi.searchVideos(query, params);
        }
        
        // 如果是第一頁，替換所有影片；否則，添加到現有影片列表
        if (currentPage === 1) {
          setVideos(data?.videos || []);
        } else {
          setVideos(prevVideos => [...prevVideos, ...(data?.videos || [])]);
        }
        
        setPagination(data?.pagination || null);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching videos:', err);
        setError(t('common.error'));
        setLoading(false);
      }
    };
    
    fetchVideos();
    
    // 組件卸載時重置頁面標題
    return () => {
      document.title = t('common.appName');
    };
  }, [categoryType, categoryValue, sort, pageTitle, t, currentPage]);
  
  // 加載更多影片
  const loadMoreVideos = async () => {
    if (!categoryType || loadingMore || !pagination || currentPage >= pagination.pages) return;
    
    try {
      setLoadingMore(true);
      
      const nextPage = currentPage + 1;
      setCurrentPage(nextPage);
      
      // 更新 URL 中的頁碼
      const newSearchParams = new URLSearchParams(location.search);
      newSearchParams.set('page', nextPage);
      navigate(`${location.pathname}?${newSearchParams.toString()}`, { replace: true });
      
      setLoadingMore(false);
    } catch (err) {
      console.error('Error loading more videos:', err);
      setLoadingMore(false);
    }
  };
  
  // 處理排序變更
  const handleSortChange = (newSort) => {
    setSort(newSort);
    
    // 重置頁碼
    const newSearchParams = new URLSearchParams(location.search);
    newSearchParams.set('page', 1);
    navigate(`${location.pathname}?${newSearchParams.toString()}`);
  };
  
  // 處理搜索
  const handleSearch = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };
  
  // 過濾數據
  const getFilteredData = (data) => {
    if (!searchTerm) return data;
    
    if (activeTab === 'manufacturers') {
      return data.filter(item => 
        item.manufacturer.toLowerCase().includes(searchTerm)
      );
    } else if (activeTab === 'actors') {
      return data.filter(item => 
        item.actor.toLowerCase().includes(searchTerm)
      );
    } else if (activeTab === 'tags') {
      return data.filter(item => 
        item.tag.toLowerCase().includes(searchTerm)
      );
    }
    
    return data;
  };
  
  // 獲取當前標籤的數據
  const getCurrentTabData = () => {
    if (activeTab === 'manufacturers') {
      return getFilteredData(allManufacturers);
    } else if (activeTab === 'actors') {
      return getFilteredData(allActors);
    } else if (activeTab === 'tags') {
      return getFilteredData(allTags);
    }
    return [];
  };
  
  if (loading && categoryType) {
    return <Loading type="category" />;
  }
  
  if (error) {
    return (
      <div className="text-center py-16">
        <p className="text-red-500 mb-4">{error}</p>
        <button 
          onClick={() => reloadPage()}
          className="px-4 py-2 bg-primary-600 text-white rounded-md"
        >
          {t('common.retry')}
        </button>
      </div>
    );
  }
  
  // 獲取當前頁面的 URL 路徑
  const getCurrentPagePath = () => {
    let path = '';
    
    if (categoryType === 'tag' && tag) {
      path = `category/tag/${encodeURIComponent(tag)}`;
    } else if (categoryType === 'manufacturer' && manufacturer) {
      path = `category/manufacturer/${encodeURIComponent(manufacturer)}`;
    } else if (categoryType === 'actor' && actor) {
      path = `category/actor/${encodeURIComponent(actor)}`;
    } else if (categoryType === 'search' && query) {
      path = `category/search/${encodeURIComponent(query)}`;
    } else {
      path = 'category';
    }
    
    return path;
  };
  
  // 獲取頁面描述
  const getPageDescription = () => {
    if (currentLanguage === 'tw') {
      return `${pageTitle} - 提供高質量影片內容，包括多種分類和標籤。免費線上日本AV影片觀看平台。`;
    } else if (currentLanguage === 'cn') {
      return `${pageTitle} - 提供高质量影片内容，包括多种分类和标签。免费在线日本AV影片观看平台。`;
    } else {
      return `${pageTitle} - Provides high-quality video content with various categories and tags. Free online Japanese AV video viewing platform.`;
    }
  };
  
  // 獲取頁面關鍵詞
  const getPageKeywords = () => {
    if (currentLanguage === 'tw') {
      return `${categoryValue || '分類'},日本AV,免費影片,線上觀看,繁體中文`;
    } else if (currentLanguage === 'cn') {
      return `${categoryValue || '分类'},日本AV,免费影片,在线观看,简体中文`;
    } else {
      return `${categoryValue || 'category'},Japanese AV,free videos,online viewing,English`;
    }
  };
  
  return (
    <div>
      {/* 簡化的 Helmet 組件來管理頭部元數據 */}
      <Helmet>
        {/* 從seo-config.json獲取標題，如果沒有則使用動態生成的標題 */}
        <title>
          {categoryType 
            ? `${pageTitle}影片 | 免費高清AV - ${t('common.appName')}` 
            : (seoConfig[currentLanguage]?.category?.title || `所有分類 | ${t('common.appName')}`)}
        </title>
        
        {/* 從seo-config.json獲取描述，如果沒有則使用動態生成的描述 */}
        <meta 
          name="description" 
          content={categoryType 
            ? getPageDescription() 
            : (seoConfig[currentLanguage]?.category?.description || getPageDescription())} 
        />
        
        {/* 從seo-config.json獲取關鍵詞，如果沒有則使用動態生成的關鍵詞 */}
        <meta 
          name="keywords" 
          content={categoryType 
            ? getPageKeywords() 
            : (seoConfig[currentLanguage]?.category?.keywords || getPageKeywords())} 
        />
        
        <link rel="canonical" href={`${getOrigin()}/${currentLanguage}/${getCurrentPagePath()}`} />
        
        {/* Open Graph 標籤 - 用於社交媒體分享 */}
        <meta 
          property="og:title" 
          content={categoryType 
            ? `${pageTitle}影片 | 免費高清AV - ${t('common.appName')}` 
            : (seoConfig[currentLanguage]?.category?.title || `所有分類 | ${t('common.appName')}`)} 
        />
        <meta 
          property="og:description" 
          content={categoryType 
            ? getPageDescription() 
            : (seoConfig[currentLanguage]?.category?.description || getPageDescription())} 
        />
        <meta 
          property="og:type" 
          content={seoConfig[currentLanguage]?.category?.ogType || "website"} 
        />
        <meta property="og:url" content={`${getOrigin()}/${currentLanguage}/${getCurrentPagePath()}`} />
        
        {/* Twitter 卡片 */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta 
          name="twitter:title" 
          content={categoryType 
            ? `${pageTitle} - ${t('common.appName')}` 
            : (seoConfig[currentLanguage]?.category?.title || `所有分類 | ${t('common.appName')}`)} 
        />
        <meta 
          name="twitter:description" 
          content={categoryType 
            ? getPageDescription() 
            : (seoConfig[currentLanguage]?.category?.description || getPageDescription())} 
        />
        
        {/* 多語言替代連結 */}
        <link rel="alternate" hreflang="zh-TW" href={`${getOrigin()}/tw/${getCurrentPagePath()}`} />
        <link rel="alternate" hreflang="zh-CN" href={`${getOrigin()}/cn/${getCurrentPagePath()}`} />
        <link rel="alternate" hreflang="en" href={`${getOrigin()}/en/${getCurrentPagePath()}`} />
        <link rel="alternate" hreflang="x-default" href={`${getOrigin()}/tw/${getCurrentPagePath()}`} />
        
        {/* 結構化數據 - JSON-LD 格式 - 簡化版 */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "CollectionPage",
            "name": categoryType ? pageTitle : t('category.allCategories'),
            "description": categoryType 
              ? getPageDescription() 
              : (seoConfig[currentLanguage]?.category?.description || getPageDescription()),
            "url": `${getOrigin()}/${currentLanguage}/${getCurrentPagePath()}`,
            "inLanguage": currentLanguage === 'tw' ? 'zh-TW' : currentLanguage === 'cn' ? 'zh-CN' : 'en'
          })}
        </script>
        
        {/* 麵包屑導航結構化數據 */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement": [
              {
                "@type": "ListItem",
                "position": 1,
                "name": t('common.appName'),
                "item": `${getOrigin()}/${currentLanguage}`
              },
              {
                "@type": "ListItem",
                "position": 2,
                "name": categoryType ? pageTitle : t('category.allCategories'),
                "item": `${getOrigin()}/${currentLanguage}/${getCurrentPagePath()}`
              }
            ]
          })}
        </script>
      </Helmet>
      
      {/* 頁面標題 */}
      <h1 className="text-2xl font-bold mb-6">{categoryType ? pageTitle : t('category.allCategories')}</h1>
      
      {/* 分類內容 */}
      {categoryType ? (
        // 顯示特定分類的影片
        <div>
          {/* 篩選和排序 */}
          <div className="mb-8 flex flex-wrap gap-4 items-center">
            {/* 排序選項 */}
            <div className="flex items-center">
              <span className="mr-2">{t('category.sort')}:</span>
              <select 
                value={sort}
                onChange={(e) => handleSortChange(e.target.value)}
                className="px-3 py-2 border rounded-md"
              >
                <option value="upload_date">{t('category.sortByDate')}</option>
                <option value="views">{t('category.sortByViews')}</option>
              </select>
            </div>
            
            {/* 顯示當前分類信息 */}
            {pagination && (
              <div className="text-gray-600">
                {t('category.videosCount', { count: pagination.total })}
              </div>
            )}
          </div>
          
          {/* 影片列表 */}
          {videos.length > 0 ? (
            <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 mb-8">
              {videos.map((video) => (
                <VideoCard key={video.hid} video={video} />
              ))}
            </div>
          ) : (
            <div className="text-center py-16 bg-white rounded-lg shadow-md">
              <p className="text-gray-500">{t('common.noResults')}</p>
            </div>
          )}
          
          {/* 加載更多按鈕 */}
          {pagination && currentPage < pagination.pages && (
            <div className="text-center mt-8">
              <button 
                onClick={loadMoreVideos}
                disabled={loadingMore}
                className="px-6 py-2 bg-primary-600 text-white rounded-md hover:bg-primary-700 transition-colors disabled:opacity-50"
              >
                {loadingMore ? (
                  <span className="flex items-center justify-center">
                    <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    {t('common.loading')}
                  </span>
                ) : (
                  t('common.loadMore')
                )}
              </button>
            </div>
          )}
        </div>
      ) : (
        // 顯示分類導航
        <div className="bg-white rounded-lg shadow-md">
          {/* 分類標籤頁 */}
          <div className="border-b">
            <div className="flex flex-wrap">
              <button
                className={`py-4 px-6 font-medium border-b-2 ${
                  activeTab === 'manufacturers' 
                    ? 'border-primary-600 text-primary-600' 
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                }`}
                onClick={() => setActiveTab('manufacturers')}
              >
                {t('video.manufacturer')}
              </button>
              <button
                className={`py-4 px-6 font-medium border-b-2 ${
                  activeTab === 'actors' 
                    ? 'border-primary-600 text-primary-600' 
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                }`}
                onClick={() => setActiveTab('actors')}
              >
                {t('video.actors')}
              </button>
              <button
                className={`py-4 px-6 font-medium border-b-2 ${
                  activeTab === 'tags' 
                    ? 'border-primary-600 text-primary-600' 
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                }`}
                onClick={() => setActiveTab('tags')}
              >
                {t('video.tags')}
              </button>
              {/* 番號標籤已隱藏 */}
            </div>
          </div>
          
          {/* 搜索框 */}
          <div className="p-4 border-b">
            {activeTab !== 'code' ? (
              <div className="relative">
                <input
                  type="text"
                  placeholder={`${t('common.search')}...`}
                  className="w-full px-4 py-2 border rounded-md pl-10"
                  value={searchTerm}
                  onChange={handleSearch}
                />
                <svg 
                  className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" 
                  width="20" 
                  height="20" 
                  fill="none" 
                  stroke="currentColor" 
                  viewBox="0 0 24 24" 
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path 
                    strokeLinecap="round" 
                    strokeLinejoin="round" 
                    strokeWidth="2" 
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  />
                </svg>
              </div>
            ) : (
              <form onSubmit={handleCodeSearchSubmit} className="flex">
                <div className="relative flex-grow">
                  <input
                    type="text"
                    placeholder={`${t('video.code')}...`}
                    className="w-full px-4 py-2 border rounded-l-md"
                    value={codeSearchTerm}
                    onChange={handleCodeSearchChange}
                  />
                </div>
                <button
                  type="submit"
                  className="px-4 py-2 bg-primary-600 text-white rounded-r-md hover:bg-primary-700 transition-colors"
                  disabled={isCodeSearching}
                >
                  {isCodeSearching ? (
                    <span className="flex items-center justify-center">
                      <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                    </span>
                  ) : (
                    t('common.search')
                  )}
                </button>
              </form>
            )}
          </div>
          
          {/* 分類內容 */}
          <div className="p-6">
            {/* 加載中提示 */}
            {loadingCategories ? (
              <div className="flex flex-col items-center justify-center py-12">
                <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary-600 mb-4"></div>
                <p className="text-gray-600">{t('common.loading')}</p>
              </div>
            ) : (
            <>
              {/* 片商列表 */}
              {activeTab === 'manufacturers' && (
                <div>
                  <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
                    {getCurrentTabData().map((manufacturerItem, index) => (
                      <Link 
                        key={index}
                        to={localizedLink(`category/manufacturer/${encodeURIComponent(manufacturerItem.manufacturer)}`, currentLanguage)}
                        className="p-4 bg-gray-50 border border-gray-200 rounded-lg hover:bg-gray-100 transition-colors text-center"
                      >
                        <div className="font-medium">{manufacturerItem.manufacturer}</div>
                        <div className="text-sm text-gray-500">
                          {manufacturerItem.video_count} 部影片
                        </div>
                      </Link>
                    ))}
                  </div>
                </div>
              )}
              
              {/* 演員列表 */}
              {activeTab === 'actors' && (
                <div>
                  <div className="flex flex-wrap gap-3">
                    {getCurrentTabData().map((actorItem, index) => (
                      <Link 
                        key={index}
                        to={localizedLink(`category/actor/${encodeURIComponent(actorItem.actor)}`, currentLanguage)}
                        className="px-4 py-2 bg-gray-100 hover:bg-gray-200 rounded-full text-gray-800 transition-colors"
                      >
                        {actorItem.actor} <span className="text-gray-500">({actorItem.video_count})</span>
                      </Link>
                    ))}
                  </div>
                </div>
              )}
              
              {/* 標籤列表 */}
              {activeTab === 'tags' && (
                <div>
                  <div className="flex flex-wrap gap-3">
                    {(() => {
                      // 標準化標籤並合併相同標籤的計數
                      const normalizedTagMap = new Map();
                      
                      getCurrentTabData().forEach(tagItem => {
                        const tagName = tagItem.tag.toLowerCase().trim();
                        
                        // 過濾掉不需要的標籤
                        if (tagName.includes('av女優片') || 
                            tagName.includes('av女优片') || 
                            tagName.includes('av actress') || 
                            tagName.includes('720p') || 
                            tagName.includes('hd') || 
                            tagName.includes('4k')) {
                          return;
                        }
                        
                        if (normalizedTagMap.has(tagName)) {
                          const existingTag = normalizedTagMap.get(tagName);
                          existingTag.video_count += tagItem.video_count;
                        } else {
                          normalizedTagMap.set(tagName, {
                            tag: tagName,
                            video_count: tagItem.video_count
                          });
                        }
                      });
                      
                      // 轉換回數組並按計數排序
                      return Array.from(normalizedTagMap.values())
                        .sort((a, b) => b.video_count - a.video_count)
                        .map((tagItem, index) => (
                          <Link 
                            key={index}
                            to={localizedLink(`category/tag/${encodeURIComponent(tagItem.tag)}`, currentLanguage)}
                            className="px-4 py-2 bg-gray-100 hover:bg-gray-200 rounded-full text-gray-800 transition-colors"
                          >
                            {tagItem.tag} <span className="text-gray-500">({tagItem.video_count})</span>
                          </Link>
                        ));
                    })()}
                  </div>
                </div>
              )}
              
              {/* 番號搜尋結果 */}
              {activeTab === 'code' && (
                <div>
                  {/* 搜尋結果 */}
                  {hasCodeSearched ? (
                    <div>
                      {codeSearchResults.length > 0 ? (
                        <div>
                          <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 gap-6 mb-8">
                            {/* 只顯示前3個結果，或者全部結果（如果點擊了"加載更多"） */}
                            {(showAllCodeResults ? codeSearchResults : codeSearchResults.slice(0, 3)).map((video) => (
                              <VideoCard key={video.hid} video={video} />
                            ))}
                          </div>
                          
                          {/* 加載更多按鈕 - 只有當結果超過3個且尚未顯示全部時才顯示 */}
                          {!showAllCodeResults && codeSearchResults.length > 3 && (
                            <div className="text-center mt-4">
                              <button 
                                onClick={loadMoreCodeResults}
                                className="px-6 py-2 bg-primary-600 text-white rounded-md hover:bg-primary-700 transition-colors"
                              >
                                {t('common.loadMore')}
                              </button>
                            </div>
                          )}
                        </div>
                      ) : (
                        <div className="text-center py-8 bg-white rounded-lg shadow-md">
                          <p className="text-gray-500">{t('common.noResults')}</p>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div>
                      {/* 熱門番號列表 */}
                      <h3 className="text-lg font-semibold mb-4">TOP10</h3>
                      {loadingTopVideos ? (
                        <div className="flex justify-center py-8">
                          <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-primary-600"></div>
                        </div>
                      ) : (
                        <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 gap-6">
                          {topVideos.map((video) => (
                            <div key={video.hid} className="bg-gray-50 p-3 rounded-md border border-gray-200">
                              <Link 
                                to={localizedLink(`video/${video.barcode || video.hid}`, currentLanguage)}
                                className="font-medium hover:text-primary-600"
                              >
                                {video.barcode || video.hid}
                              </Link>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Category;
